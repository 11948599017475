import ReconnectingWebSocket from 'reconnecting-websocket';

const APP_WS = `wss://api.forum.blockchainstudios.stream/ws` || process.env.REACT_APP_WS

export const getSocket = (): ReconnectingWebSocket => new ReconnectingWebSocket(APP_WS!);

let socket: ReconnectingWebSocket | null = null;
export const getSameSocket = (): ReconnectingWebSocket => {
  if (!socket) {
    socket = new ReconnectingWebSocket(APP_WS!);
  }

  return socket;
};
