import React from 'react';
import { LandingLayout } from '../components/layouts/LandingLayout';
import { Hero } from '../components/sections/Hero';

export const Landing: React.FC = () => (
  <LandingLayout>
    <Hero
      title="Blockchain Studios Forum"
      subtitle="Whether you’re a Patron, a film student,
        or a movie making legend, 
        Blockchain Studios Forum makes it easy to talk every day and hang out more often."
      image={`${process.env.PUBLIC_URL}/logo.png`}
      ctaText="Get Started"
      ctaLink="/register"
    />
  </LandingLayout>
);
